import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'


export const homePageQuery = graphql`
query {
  prismic {
    page(uid: "index", lang: "en-gb") {
      _meta {
        uid
      }
      title
      body {
        ... on PRISMIC_PageBodyText {
          type
          primary {
            text
          }
        }
        ... on PRISMIC_PageBodyCard_deck {
          type
          primary {
            deckTitle
          }
          fields {
            image
            text
            title
            button_link {
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                  tags
                  lang
                }
              }
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
            }
            featured
          }
        }
        ... on PRISMIC_PageBodyText_with_button {
          type
          primary {
            text
            button_text
            button_link {
              _linkType
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                  tags
                  lang
                }
              }
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
            }
          }
        }
        ... on PRISMIC_PageBodyQuote {
          type
          primary {
            role
            quote
            image
            company
            author
          }
        }
        ... on PRISMIC_PageBodyHero {
          type
          primary {
            image
            title
            text
            button_text
            button_link {
              _linkType
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                  tags
                  lang
                }
              }
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
            }
          }
        }
        ... on PRISMIC_PageBodyBanner_with_caption {
          type
          primary {
            image
            title
            text
            button_text
            button_link {
              _linkType
              ... on PRISMIC_Page {
                _meta {
                  uid
                  type
                  tags
                  lang
                }
              }
              ... on PRISMIC__ExternalLink {
                url
              }
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
            }
          }
        }
        ... on PRISMIC_PageBodyImage {
          type
          primary {
            image
          }
        }
        ... on PRISMIC_PageBodyImage_gallery {
          type
          label
          primary {
            gallery_name
          }
          fields {
            image
          }
        }
        ... on PRISMIC_PageBodyList {
          type
          label
          primary {
            listTitle
          }
          fields {
            list_item_image
            list_item_text
            lot
            price
            price_achieved
          }
        }
        ... on PRISMIC_PageBodyMap {
          type
          primary {
            google_maps
          }
        }
        ... on PRISMIC_PageBodyVideo {
          type
          label
          primary {
            embed_video
            video_link {
              ... on PRISMIC__FileLink {
                name
                url
                size
              }
            }
            video_poster
            video_title
          }
        }
      }
    }
  }
}`

const HomePage = ({ data }) => {
  const homePage = data.prismic.page
  return (
    <Layout>
      <SEO title={homePage.meta_title ? (homePage.meta_title):(homePage.title)} keywords={homePage.meta_keywords} description={homePage.meta_description} lang='en-gb' />
      <div>
        {homePage.body !== null && homePage.body.length > 0 && homePage.body.map((slice, index) => {
          return (
            <React.Fragment key={`slice-${index}`}>
              <SliceList slice={slice} />
            </React.Fragment>
          )
        })}
      </div>
    </Layout>
  )
}
export default HomePage
